// AlbumHeader.js
import React, { useState } from "react";
import { Typography, TextField, IconButton, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../lib/theme";
import RefreshLink from "../../shared/RefreshLink";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../shared/firebase";
import ConfirmDialog from '../../shared/ConfirmDialog';

const AlbumHeader = ({ albumId, title, subTitle, creatorName, userId, currentUserId, privacyLevel }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isEditingSubTitle, setIsEditingSubTitle] = useState(false);
  const [editedSubTitle, setEditedSubTitle] = useState(subTitle);
  const navigate = useNavigate();

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedTitle(title);
  };

  const handleSaveTitle = async () => {
    try {
      const albumRef = doc(db, "albums", albumId);
      await updateDoc(albumRef, {
        title: editedTitle,
        updatedAt: new Date(),
      });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating album title:", error);
      alert("Failed to update album title. Please try again.");
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedTitle(title);
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const albumRef = doc(db, "albums", albumId);
      await deleteDoc(albumRef);
      handleCloseDeleteDialog();
      navigate("/dashboard"); // Redirect to dashboard after deletion
    } catch (error) {
      console.error("Error deleting album:", error);
      alert("Failed to delete album. Please try again.");
    }
  };

  const handleSubTitleChange = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newSubTitle = event.target.value.trim();
      if (newSubTitle !== subTitle) {
        try {
          const albumRef = doc(db, "albums", albumId);
          await updateDoc(albumRef, {
            subTitle: newSubTitle,
            updatedAt: new Date(),
          });
          setEditedSubTitle(newSubTitle);
        } catch (error) {
          console.error("Error updating subtitle:", error);
          alert("Failed to update subtitle. Please try again.");
        }
      }
      setIsEditingSubTitle(false);
    }
  };

  const handleSaveSubTitle = async () => {
    if (editedSubTitle !== subTitle) {
      try {
        const albumRef = doc(db, "albums", albumId);
        await updateDoc(albumRef, {
          subTitle: editedSubTitle,
          updatedAt: new Date(),
        });
      } catch (error) {
        console.error("Error updating subtitle:", error);
        alert("Failed to update subtitle. Please try again.");
      }
    }
    setIsEditingSubTitle(false);
  };

  const getPrivacyMessage = () => {
    switch (privacyLevel) {
      case 0:
        return 'A private album';
      case 1:
        return 'A shared album';
      case 2:
        return 'A public album';
      default:
        return 'An album';
    }
  };

  return (
    <>
      {isEditing ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            variant="standard"
            sx={{ input: { color: colors.black, fontSize: '2.125rem', fontWeight: 400 } }}
          />
          <IconButton onClick={handleSaveTitle} sx={{ color: colors.gold }}>
            <CheckIcon />
          </IconButton>
          <IconButton onClick={handleCancelEdit} sx={{ color: colors.gold }}>
            <CloseIcon />
          </IconButton>
        </div>
      ) : (
        <Typography variant="h3" sx={{ color: colors.gold, mb: 0, mt: 2, display: 'flex', alignItems: 'center' }}>
          {title || "No Title"}
          {currentUserId === userId && (
            <>
              <IconButton onClick={handleEditClick} sx={{ color: colors.gold, ml: 1 }}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleDeleteClick} sx={{ color: colors.gold, ml: 1 }}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </Typography>
      )}
      {currentUserId === userId && (
        <Box sx={{ mt: 0, mb: 1, display: 'flex', alignItems: 'center' }}>
          {isEditingSubTitle ? (
            <>
              <TextField
                variant="outlined"
                placeholder="Add a subtitle"
                value={editedSubTitle}
                onChange={(e) => setEditedSubTitle(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSaveSubTitle()}
                autoFocus
                sx={{
                  input: { color: colors.black },
                  width: '300px',
                  mr: 1
                }}
              />
              <Button onClick={handleSaveSubTitle} sx={{ color: colors.gold }}>
                Save
              </Button>
            </>
          ) : (
            <Typography
              variant="subtitle1"
              onClick={() => setIsEditingSubTitle(true)}
              sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' }, color: colors.white }}
            >
              {editedSubTitle || "Add a subtitle"}
            </Typography>
          )}
        </Box>
      )}
      {creatorName && (
        <Typography variant="body2" sx={{ color: colors.white, pt: 1 }}>
          {`${getPrivacyMessage()} created by: `}
          <RefreshLink to={`/user/${userId}`} style={{ color: colors.gold }}>
            {creatorName}
          </RefreshLink>
        </Typography>
      )}

      <ConfirmDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        title="Delete Album"
        content="Are you sure you want to delete this album? This action cannot be undone."
      />
    </>
  );
};

export default AlbumHeader;
